<template>
	<div class="com-delivery-list">
		<a-space class="mb12">
			<a-tooltip title="仅导出待配送+已选中的订单">
				<a-button v-has="{action:'wxapp_land_send_order_out',plat:isShop}" @click="outputDelivery(pks)">
					导出订单
				</a-button>
			</a-tooltip>
			<a-button @click="confirmSendBatch(pks)">确认收货</a-button>
			<com-print-face-order
				btn-type="default"
				:type="4" 
				:order-id="pks">
			</com-print-face-order>
			<com-upload-btn btn-text="批量发货" :custom-upload="true" @uploadChange="uploadChange"></com-upload-btn>
			<a href="https://www.kancloud.cn/cqkundian/kundian_farm/1860709" target="_blank" v-if="isShop == 0">
				<a-button type="link">批量发货操作指南</a-button>	
			</a>
			<a href="https://www.kancloud.cn/cqkundian/kundian_farm/1873797" target="_blank" v-if="isShop == 0">
				<a-button type="link">自动生成配送订单指南</a-button>	
			</a>
		</a-space>
		
		<a-table :pagination="false" row-key="id" :data-source="list" :columns="[
			{title:'订单信息',dataIndex:'order_number',slots:{customRender:'order_number'}},
			{title:'配送信息',dataIndex:'delivery',slots:{customRender:'delivery'}},
			{title:'收货人',dataIndex:'user',slots:{customRender:'user'}},
			{title:'支付状态',dataIndex:'pay_status',slots:{customRender:'pay_status'}},
			{title:'发货状态',dataIndex:'send_status',slots:{customRender:'send_status'}},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'},width:'240px'},
		]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
			<template #order_number="{record}">
				<div><span class="f12">ID：</span>{{record.id}}</div>
				<div><span class="f12">编号：</span>{{record.order_number}}</div>
				<div><span class="f12">下单时间：</span>{{record.create_time}}</div>
				<div><span class="f12">预计配送：</span>{{record.pre_delivery_time}}</div>
			</template>
			<template #user="{record}">
				<div><span class="f12">收货人：</span>{{record.name}},{{record.phone}}</div>
				<div><span class="f12">地 址：</span>{{record.address}}</div>
			</template>
			<template #address="{record}">
				<span>{{record.address}}</span>
			</template>
			<template #delivery="{record}">
				<div v-if="record.order && sence == 'list'">
					<span class="f12">土地订单：</span>
					<router-link :to="{path:'/land/orderDetail',query:{order_id:record.order.id}}">
						<span style="color:#0099FF;cursor: pointer;" @click="toRentOrder(record.order.id)">
							{{record.order.order_number}}
						</span>
					</router-link>
				</div>
				<div v-if="record.order && record.order.land">
					<span class="f12">地块信息：</span>{{record.order.land.land_name}}
				</div>
				<div v-if="record.order && record.order.land">
					<span class="f12">地块编号：</span>{{record.order.spec.land_num}}
				</div>
				<div v-if="record.delivery_weight"><span class="f12">配送重量：</span>{{record.delivery_weight}}kg</div>
				<div v-if="record.remark"><span class="f12">备 注：</span>{{record.remark}}</div>
			</template>
			
			<template #pay_status="{record}">
				<p v-if="record.pay_status==1 &&record.is_free_delivery == 0">
					<span class="f12">支付金额：</span>{{record.total_price}}
				</p>
				<a-tag color="purple" v-if="record.is_free_delivery == 1">免费配送</a-tag>
				<a-tag v-else :color="$status.getSendOrderStatus(record,'pay',true)">
					{{$status.getSendOrderStatus(record)}}
				</a-tag>
			</template>
			
			<template #send_status="{record}">
				<div v-if="record.express_company"><span class="f12">快递公司：</span>{{record.express_company || '-'}}</div>
				<div v-if="record.express_no"><span class="f12">快递单号：</span>{{record.express_no || '-'}}</div>
				<a-tag color="green">{{$status.getSendOrderStatus(record,'send')}}</a-tag>
				<div v-if="record.runing" style="margin-top: 5px;">
					<a-tooltip>
						<template #title>
							<template v-if="record.runing.run_type == 'dada'">
								<div>配送员：{{record.runing.dm_name}}</div>
								<div>配送员电话：{{record.runing.dm_mobile}}</div>
							</template>
							<template v-else>
								<div v-if="record.runing.persion">配送员：{{record.runing.persion.name}}</div>
								<div v-if="record.runing.persion">配送员电话：{{record.runing.persion.mobile}}</div>
							</template>
							<div>取货时间：{{record.runing.pickup_time || '--'}}</div>
							<div>送达时间：{{record.runing.confirm_time || '--'}}</div>
							<div v-if="record.runing.cancel_reason">取消理由：{{record.runing.cancel_reason}}</div>
						</template>
						<a-tag color="#00CCCC" v-if="record.runing.run_type == 'ziying'">商家配送</a-tag>
						<a-tag color="#CC66FF" v-if="record.runing.run_type == 'dada'">达达配送</a-tag>
					</a-tooltip>
					<div v-if="record.runing.cancel_reason" style="color: red;">取消理由：{{record.runing.cancel_reason}}</div>
				</div>
			</template>
			<template #action="{record}">
				<a-space style="display: flex;align-items: center;">
					<span
						v-if="$status.checkSendOrderopera(record,'send')" 
						v-has="{action:'wxapp_land_send_order_send',plat:isShop}">
						<com-deliver-goods
							btn-type="primary"
							btn-size="small" 
							:id="record.id" 
							:type="4" 
							:is-shop="isShop" 
							@success="refreshCom">
						</com-deliver-goods>
					</span>
					<a-button
						v-has="{action:'wxapp_land_send_order_confirm',plat:isShop}" 
						v-if="record.delivery_status == 1 " 
						type="primary" 
						size="small"
						@click="handleDeliveryOrder(record.id,'confirm','确认收货吗？')">确认收货
					</a-button>
					<router-link :to="{path:isShop == 0 ?'/land/delivery_detail':'/many_store/land/delivery_detail',query:{id:record.id}}">
						<a-button size="small" v-has="{action:'/land/delivery_detail',plat:isShop}" type="primary" >
							详情
						</a-button>
					</router-link>
					<a-dropdown v-if="record.pay_status == 1">
						<a-button type="primary" size="small">更多操作<i class="ri-arrow-down-s-line"></i></a-button>
					    <template #overlay>
							<a-menu>
								<a-menu-item v-if="$status.checkSendOrderopera(record,'cancel')">
									<a-button type="link" 
										v-has="{action:'wxapp_land_send_order_cancel',plat:isShop}" 
										@click="handleDeliveryOrder(record.id,'cancel','确认取消配送订单吗？')">取消
									</a-button>
								</a-menu-item>
								<a-menu-item v-if="$status.checkSendOrderopera(record,'send') && isShop == 0">
									<com-print-face-order
										btn-type="link"
										btn-size="small"
										:type="4" 
										:order-id="[record.id]"  
										@close="refreshCom">
									</com-print-face-order>
								</a-menu-item>
								<a-menu-item v-if="record.delivery_status >=1 && record.express_company !='商家配送'">
									<com-logistics 
										v-has="{action:'wxapp_land_send_order_logistics',plat:isShop}" 
										:order-type="4" :pk="record.id" 
										btn-type="link" 
										btn-text="查看物流">
									 </com-logistics>
								</a-menu-item>
								<a-menu-item v-if="$status.checkSendOrderopera(record,'refund')">
									<a-button type="link" 
										v-has="{action:'wxapp_land_send_order_refund',plat:isShop}" 
										@click="handleDeliveryOrder(record.id,'refund_yes','确认对该订单进行退款操作吗？')" >退款
									</a-button>
								</a-menu-item>
							</a-menu>
					    </template>
					</a-dropdown>
				</a-space>
			</template>
		</a-table>
	</div>
</template>

<script>
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import comLogistics from '@/components/miniapp/com-logistics.vue'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
export default{
	name:'com-delivery-list',
	components:{
		comLogistics,
		comDeliverGoods,
		comPrintFaceOrder,
		comUploadBtn,
	},
	props:{
		list:{
			type:Array,
			value:[]
		},
		isShop:{
			type:Number,
			default:0
		},
		jumpDetail:{
			type:Boolean,
			default:false
		},
		sence:{	//组件使用场景 list  detail
			type:String,
			default:'list'
		}
	},
	setup(props,context){
		const _d = reactive({
			pks:[],
		})
		const handleDeliveryOrder = (id,op,title)=>{
			landModel.setDeliveryStatus(op,id,title,()=>refreshCom())
		}
		const confirmSendBatch = (ids)=>landModel.setBatchDeliveryOrderConfirm(ids,()=>refreshCom())
		const uploadChange = (e)=>landModel.batchSendOrder(e)
		const outputDelivery = (ids)=>landModel.exportDeliveryOrder(ids)
		const refreshCom = ()=> context.emit("success",true)
		return{
			...toRefs(_d),
			handleDeliveryOrder,
			confirmSendBatch,
			uploadChange,
			outputDelivery,
			refreshCom
		}
	},
}
</script>

<style>
</style>
